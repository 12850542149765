import { globalStyles } from "@/utils/globalStyles"
import React from "react"
import SVGLogo from "../SVGs/SVGLogo"
import { useSyncStatesWithSocket } from "@/utils/useSyncStatesWithSocket"
import { useIsMaster } from "@/utils/isMaster"

export default function Splash() {
  const { syncStatesWithSocket } = useSyncStatesWithSocket()
  const { isMaster } = useIsMaster()
  return (
    <div
      style={{
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        display: "grid",
        placeItems: "center",
        backgroundColor: globalStyles.col.grey,
        zIndex: -1,
        height: "100%",
        width: "100%",
      }}
    >
      <SVGLogo
        color={globalStyles.col.purple}
        onClick={() => syncStatesWithSocket({ section: "MASTERPLAN" })}
        style={{ width: "18rem", pointerEvents: isMaster ? "auto" : "none" }}
      />
    </div>
  )
}
