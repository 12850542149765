#include <alphatest_pars_fragment>
#include <alphahash_pars_fragment>

in vec4 vColor;
in vec3 vPosition;
in vec4 vCenter_2;
in float vTest;

uniform float uOpacity;
uniform vec2 uPointer;

void main() {
  float A = -dot(vPosition.xy, vPosition.xy);
  if(A < -4.0)
    discard;
  float B = exp(A) * vColor.a;
  vec4 diffuseColor = vec4(vColor.rgb, B);
      #include <alphatest_fragment>
      #include <alphahash_fragment>

  vec3 highlight = vec3(0.0, 0.0, 1.0);
  float mini = uPointer.x * 2.0;
  float maxi = mini + 0.4;

  // vec4 highlighted = mix(diffuseColor, vec4(1.0, 0.4, 0.6, diffuseColor.a), uOpacity);
  vec4 highlighted = mix(diffuseColor, vec4(1.0, 0.4, 0.6, diffuseColor.a), uOpacity);

  // if(vCenter_2.x < maxi && vCenter_2.x > mini) {
  // if(vTest > 0.5) {
  //   gl_FragColor = vec4(diffuseColor.r, diffuseColor.g, diffuseColor.b, diffuseColor.a * uOpacity);
  // } else {
  //   gl_FragColor = vec4(diffuseColor.r, diffuseColor.g, diffuseColor.b, diffuseColor.a * 0.0);
  // }
  gl_FragColor = vec4(diffuseColor.r, diffuseColor.g, diffuseColor.b, diffuseColor.a * uOpacity);
  // gl_FragColor = highlighted;

  // gl_FragColor = vec4(diffuseColor.r, diffuseColor.g, diffuseColor.b, diffuseColor.a * uOpacity);

  #include <tonemapping_fragment>
  #include <colorspace_fragment>

}