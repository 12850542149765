import React from "react"
import TCanvas from "./TCanvas"
import Back from "./Back"
import { useStore } from "@/state/store"
import { useSyncStatesWithSocket } from "@/utils/useSyncStatesWithSocket"
import { useIsMaster } from "@/utils/isMaster"

export default function Masterplan() {
  const activeId = useStore((state) => state.activeId)
  const { syncStatesWithSocket } = useSyncStatesWithSocket()
  const { isMaster } = useIsMaster()
  return (
    <>
      <TCanvas />
      {isMaster && <Back />}
    </>
  )
}
