import { io, Socket } from "socket.io-client"

class SocketClient {
  private static instance: SocketClient
  private socket: Socket

  // private constructor(url: string = `http://${process.env.NEXT_PUBLIC_SOCKET_IP}:${process.env.NEXT_PUBLIC_SOCKET_PORT}`) {
  // private constructor(url: string = `http://${process.env.NEXT_PUBLIC_SOCKET_IP}:${process.env.NEXT_PUBLIC_SOCKET_PORT}`) {
  private constructor(url: string = import.meta.env.VITE_SOCKET_IP) {
    this.socket = io(url, {
      autoConnect: true,
      reconnection: true,
      reconnectionAttempts: 10,
      reconnectionDelay: 1000,
    })

    this.socket?.on("connect", () => {
      console.log("ON connected")
    })
    this.socket?.on("disconnect", () => {
      console.log("disconnected")
    })
  }

  public static getInstance(url?: string): SocketClient {
    if (!SocketClient.instance) {
      SocketClient.instance = new SocketClient(url)
    }
    return SocketClient.instance
  }

  public getSocket(): Socket {
    return this.socket
  }
}

export default SocketClient
