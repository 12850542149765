import styled from "styled-components"

function ErrorMessage() {
  return <Wrapper></Wrapper>
}

export default ErrorMessage

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
