import * as THREE from "three"
import { shaderMaterial } from "@react-three/drei"
import vertex from "./glsl/splat.vert"
import fragment from "./glsl/splat.frag"

export const SplatMaterial = /* @__PURE__ */ shaderMaterial(
  {
    alphaTest: 0,
    viewport: /* @__PURE__ */ new THREE.Vector2(1980, 1080),
    focal: 1000.0,
    centerAndScaleTexture: null,
    covAndColorTexture: null,
    uOpacity: 0.01,
    uPointer: new THREE.Vector2(0, 0),
  },
  vertex,
  fragment,
)
