import _extends from "@babel/runtime/helpers/esm/extends"
import * as THREE from "three"
import { lazyLoad } from "./splat_loader"

export function update(camera, shared, target, hashed) {
  camera.updateMatrixWorld()
  shared.gl.getCurrentViewport(target.viewport)
  // @ts-ignore
  target.material.viewport.x = target.viewport.z
  // @ts-ignore
  target.material.viewport.y = target.viewport.w
  target.material.focal =
    (target.viewport.w / 2.0) * Math.abs(camera.projectionMatrix.elements[5])

  if (target.ready) {
    if (hashed && target.sorted) return
    target.ready = false
    const view = new Float32Array([
      target.modelViewMatrix.elements[2],
      -target.modelViewMatrix.elements[6],
      target.modelViewMatrix.elements[10],
      target.modelViewMatrix.elements[14],
    ])
    shared.worker.postMessage(
      {
        method: "sort",
        src: shared.url,
        key: target.uuid,
        view: view.buffer,
        hashed,
      },
      [view.buffer],
    )
    if (hashed && shared.loaded) target.sorted = true
  }
}

export function connect(shared, target) {
  if (!shared.loading) lazyLoad(shared)
  target.ready = false
  // target.pm = new THREE.Matrix4()
  // target.vm1 = new THREE.Matrix4()
  // target.vm2 = new THREE.Matrix4()
  target.viewport = new THREE.Vector4()
  let splatIndexArray = new Uint32Array(
    shared.bufferTextureWidth * shared.bufferTextureHeight,
  )
  const splatIndexes = new THREE.InstancedBufferAttribute(
    splatIndexArray,
    1,
    false,
  )
  /////////////////////////////////
  let bufferSize = shared.bufferTextureWidth * shared.bufferTextureHeight
  let testBufferAttr = new Uint32Array(bufferSize)
  // let length = testBufferAttr.length
  for (let i = 0; i < bufferSize; i++) {
    // testBufferAttr[i] = bufferSize - i
    testBufferAttr[i] = i
  }
  // Create the InstancedBufferAttribute
  const testBufferAttrIndex = new THREE.InstancedBufferAttribute(
    testBufferAttr,
    1,
    false,
  )
  /////////////////////////////////
  splatIndexes.setUsage(THREE.DynamicDrawUsage)
  const geometry = (target.geometry = new THREE.InstancedBufferGeometry())
  const positionsArray = new Float32Array(6 * 3)
  const positions = new THREE.BufferAttribute(positionsArray, 3)
  /////////////////////////////////
  geometry.setAttribute("testAtt", testBufferAttrIndex)
  /////////////////////////////////
  geometry.setAttribute("position", positions)
  positions.setXYZ(2, -2.0, 2.0, 0.0)
  positions.setXYZ(1, 2.0, 2.0, 0.0)
  positions.setXYZ(0, -2.0, -2.0, 0.0)
  positions.setXYZ(5, -2.0, -2.0, 0.0)
  positions.setXYZ(4, 2.0, 2.0, 0.0)
  positions.setXYZ(3, 2.0, -2.0, 0.0)
  positions.needsUpdate = true
  geometry.setAttribute("splatIndex", splatIndexes)
  geometry.instanceCount = 1

  // MANIPULATE SPLAT INDEX BUFFERS
  function listener(e) {
    if (target && e.data.key === target.uuid) {
      let indexes = new Uint32Array(e.data.indices)
      // console.log(geometry.attributes.splatIndex.array[2000])
      // console.log(geometry.attributes.testAtt.array[2000])
      // @ts-ignore
      geometry.attributes.splatIndex.set(indexes)
      // geometry.attributes.testAtt.set(indexes)
      geometry.attributes.testAtt.needsUpdate = true
      geometry.attributes.splatIndex.needsUpdate = true
      geometry.instanceCount = indexes.length
      target.ready = true
    }
  }

  shared.worker.addEventListener("message", listener)

  async function wait() {
    while (true) {
      const centerAndScaleTextureProperties = shared.gl.properties.get(
        shared.centerAndScaleTexture,
      )
      const covAndColorTextureProperties = shared.gl.properties.get(
        shared.covAndColorTexture,
      )
      if (
        centerAndScaleTextureProperties != null &&
        centerAndScaleTextureProperties.__webglTexture &&
        covAndColorTextureProperties != null &&
        covAndColorTextureProperties.__webglTexture &&
        shared.loadedVertexCount > 0
      )
        break
      await new Promise((resolve) => setTimeout(resolve, 10))
    }
    target.ready = true
  }
  wait()
  return () => {
    // console.log("RETURN")
    shared.worker.removeEventListener("message", listener)
  }
}

export function pushDataBuffer(shared, buffer, vertexCount) {
  // console.log("buffer::", buffer)
  const context = shared.gl.getContext()
  if (shared.loadedVertexCount + vertexCount > shared.maxVertexes)
    vertexCount = shared.maxVertexes - shared.loadedVertexCount
  if (vertexCount <= 0) throw "Failed to parse file"
  const u_buffer = new Uint8Array(buffer)
  const f_buffer = new Float32Array(buffer)
  const matrices = new Float32Array(vertexCount * 16)
  // console.log("::pushDataBuffer::" , f_buffer)
  ///////////////////
  const testMatrix = new Float32Array(vertexCount * 3)
  ///////////////////
  const covAndColorData_uint8 = new Uint8Array(shared.covAndColorData.buffer)
  const covAndColorData_int16 = new Int16Array(shared.covAndColorData.buffer)
  for (let i = 0; i < vertexCount; i++) {
    const quat = new THREE.Quaternion(
      -(u_buffer[32 * i + 28 + 1] - 128) / 128.0,
      (u_buffer[32 * i + 28 + 2] - 128) / 128.0,
      (u_buffer[32 * i + 28 + 3] - 128) / 128.0,
      -(u_buffer[32 * i + 28 + 0] - 128) / 128.0,
    )
    quat.invert()
    const center = new THREE.Vector3(
      f_buffer[8 * i + 0],
      f_buffer[8 * i + 1],
      -f_buffer[8 * i + 2],
    )
    const scale = new THREE.Vector3(
      f_buffer[8 * i + 3 + 0],
      f_buffer[8 * i + 3 + 1],
      f_buffer[8 * i + 3 + 2],
    )
    // console.log(center)
    const mtx = new THREE.Matrix4()
    mtx.makeRotationFromQuaternion(quat)
    mtx.transpose()
    mtx.scale(scale)
    const mtx_t = mtx.clone()
    mtx.transpose()
    mtx.premultiply(mtx_t)
    mtx.setPosition(center)
    const cov_indexes = [0, 1, 2, 5, 6, 10]
    let max_value = 0.0
    for (let j = 0; j < cov_indexes.length; j++)
      if (Math.abs(mtx.elements[cov_indexes[j]]) > max_value)
        max_value = Math.abs(mtx.elements[cov_indexes[j]])
    let destOffset = shared.loadedVertexCount * 4 + i * 4
    shared.centerAndScaleData[destOffset + 0] = center.x
    shared.centerAndScaleData[destOffset + 1] = -center.y
    shared.centerAndScaleData[destOffset + 2] = center.z
    shared.centerAndScaleData[destOffset + 3] = max_value / 32767.0
    destOffset = shared.loadedVertexCount * 8 + i * 4 * 2

    // console.log(cov_indexes.length)
    for (let j = 0; j < cov_indexes.length; j++)
      covAndColorData_int16[destOffset + j] =
        (mtx.elements[cov_indexes[j]] * 32767.0) / max_value

    // RGBA
    destOffset = shared.loadedVertexCount * 16 + (i * 4 + 3) * 4

    let col

    col = new THREE.Color(
      u_buffer[32 * i + 24 + 0] / 255,
      u_buffer[32 * i + 24 + 1] / 255,
      u_buffer[32 * i + 24 + 2] / 255,
    )

    col.convertSRGBToLinear()
    covAndColorData_uint8[destOffset + 0] = col.r * 255
    covAndColorData_uint8[destOffset + 1] = col.g * 255
    covAndColorData_uint8[destOffset + 2] = col.b * 255
    covAndColorData_uint8[destOffset + 3] = u_buffer[32 * i + 24 + 3]

    // Store scale and transparent to remove splat in sorting process
    mtx.elements[15] =
      (Math.max(scale.x, scale.y, scale.z) * u_buffer[32 * i + 24 + 3]) / 255.0
    for (let j = 0; j < 16; j++) matrices[i * 16 + j] = mtx.elements[j]
  }

  // console.log("AFTER SET", shared)

  while (vertexCount > 0) {
    let width = 0
    let height = 0
    const xoffset = shared.loadedVertexCount % shared.bufferTextureWidth
    const yoffset = Math.floor(
      shared.loadedVertexCount / shared.bufferTextureWidth,
    )
    if (shared.loadedVertexCount % shared.bufferTextureWidth != 0) {
      width =
        Math.min(shared.bufferTextureWidth, xoffset + vertexCount) - xoffset
      height = 1
    } else if (Math.floor(vertexCount / shared.bufferTextureWidth) > 0) {
      width = shared.bufferTextureWidth
      height = Math.floor(vertexCount / shared.bufferTextureWidth)
    } else {
      width = vertexCount % shared.bufferTextureWidth
      height = 1
    }
    const centerAndScaleTextureProperties = shared.gl.properties.get(
      shared.centerAndScaleTexture,
    )

    context.bindTexture(
      context.TEXTURE_2D,
      centerAndScaleTextureProperties.__webglTexture,
    )

    context.texSubImage2D(
      context.TEXTURE_2D,
      0,
      xoffset,
      yoffset,
      width,
      height,
      context.RGBA,
      context.FLOAT,
      shared.centerAndScaleData,
      shared.loadedVertexCount * 4,
    )
    const covAndColorTextureProperties = shared.gl.properties.get(
      shared.covAndColorTexture,
    )
    context.bindTexture(
      context.TEXTURE_2D,
      covAndColorTextureProperties.__webglTexture,
    )
    context.texSubImage2D(
      context.TEXTURE_2D,
      0,
      xoffset,
      yoffset,
      width,
      height,
      // @ts-ignore
      context.RGBA_INTEGER,
      context.UNSIGNED_INT,
      shared.covAndColorData,
      shared.loadedVertexCount * 4,
    )
    shared.gl.resetState()
    shared.loadedVertexCount += width * height
    vertexCount -= width * height
  }

  return matrices
}
