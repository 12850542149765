import { globalStyles } from "@/utils/globalStyles"
import NavButton from "./NavButton"
import SVGLogo from "../SVGs/SVGLogo"
import { useSyncStatesWithSocket } from "@/utils/useSyncStatesWithSocket"
import { useStore } from "@/state/store"

const nav = [
  // { label: "OPENING STATEMENT" },
  { label: "MASTERPLAN" },
  { label: "VILLAS" },
  // { label: "GALLERY" },
] as const

export default function Nav() {
  const section = useStore((state) => state.section)
  const { syncStatesWithSocket } = useSyncStatesWithSocket()
  return (
    <div
      style={{
        placeSelf: "start start",
        gridColumn: "1 / -1",
        gridRow: 1,
        // height: "10vh",
        display: "grid",
        gridAutoFlow: "column",
        gap: "2rem",
        placeItems: "center start",
        marginTop: "4rem",
        marginLeft: "8rem",
      }}
    >
      <SVGLogo
        color={section === "MASTERPLAN" ? "#fff" : globalStyles.col.purple}
        onClick={() => syncStatesWithSocket({ section: "SPLASH" })}
        style={{ width: "8rem", marginRight: "8rem" }}
      />
      {nav.map((navItem, i) => {
        const { label } = navItem
        return (
          <NavButton
            key={i}
            label={label}
            lineCol={
              section === "MASTERPLAN" ? "#fff" : globalStyles.col.purple
            }
            style={{}}
            onClick={() => syncStatesWithSocket({ section: label })}
          />
        )
      })}
    </div>
  )
}
