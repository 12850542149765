import { InitialState, useStore } from "@/state/store"
import { globalStyles } from "@/utils/globalStyles"
import { useSyncStatesWithSocket } from "@/utils/useSyncStatesWithSocket"

export default function NavButton({ label, lineCol, style, ...props }) {
  const section = useStore((s) => s.section)
  return (
    <button
      style={{
        textTransform: "uppercase",
        textDecoration: "none",
        boxShadow: "none",
        border: "none",
        background: "none",
        marginTop: "1rem",
        display: "grid",
        placeItems: "end center",
        gridAutoFlow: "row",
        rowGap: "0.6rem",
        padding: "2rem",
        ...style,
      }}
      {...props}
    >
      <span
        style={{
          fontSize: "1.6rem",
          fontFamily: "Proxima",
          fontWeight: 800,
          lineHeight: "1.6rem",
          letterSpacing: "0.07rem",
          opacity: section === label ? 1 : 0.5,
          transition: "opacity 0.3s",
          color: lineCol,
        }}
      >
        {label}
      </span>
      <span
        style={{
          backgroundColor: lineCol,
          height: "1px",
          width: "95%",
          opacity: section === label ? 1 : 0,
          transition: "opacity 0.3s",
        }}
      />
    </button>
  )
}
