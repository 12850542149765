import React from "react"
import Label from "./Label"
import { Vector3 } from "three"
import { useStore } from "@/state/store"
import { useSyncStatesWithSocket } from "@/utils/useSyncStatesWithSocket"

const Labels = () => {
  const activeId = useStore((state) => state.activeId)
  const { syncStatesWithSocket } = useSyncStatesWithSocket()
  const handleBack = (number) => {
    const newActiveId = number
    syncStatesWithSocket({ activeId: newActiveId })
  }

  return (
    <>
      {activeId === 0 && (
        <Label
          id={0}
          activeId={activeId}
          setActiveId={() => handleBack(1)}
          text="Diriyah Square"
          position={new Vector3(0, 2.5, 0)}
          height={0.75}
        />
      )}
      {activeId === 1 && (
        <Label
          id={1}
          activeId={activeId}
          setActiveId={() => handleBack(2)}
          text="Enter"
          position={new Vector3(0, 2.5, 0)}
          height={0.75}
        />
      )}

      {activeId === 2 && (
        <Label
          id={2}
          activeId={activeId}
          setActiveId={() => handleBack(3)}
          text="Anchor Lifestlye Retail"
          position={new Vector3(0.25, 1.15, 0)}
          height={1}
        />
      )}
      {activeId === 3 && (
        <Label
          id={3}
          activeId={activeId}
          setActiveId={null}
          text="C01-004"
          position={new Vector3(0.1, 0.65, -0.2)}
          height={0.7}
        />
      )}
    </>
  )
}

export default Labels
