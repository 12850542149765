import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react"
import SocketClient from "@/lib/socket/io.client"
import { Socket } from "socket.io-client"

export type Room = "room1" | "room2" | "room3" | "disconnect" | null

type SocketIOContextType = {
  socket: Socket | null
  room: Room | null
  roomTaken: any
  usersInRooms: any
  setRoomTaken?: (isTaken: boolean) => void
  joinRoom: (args: { room: Room; payload?: any }) => void
  disconnectSocket?: (room: Room) => void
  emitSocketEvent?: (eventName: any, data: any) => void
  emitSyncStateEvent?: (data: any) => void
  emitSyncMultipleStateEvent?: (data: any) => void
  onSocketEvent?: (eventName: any, callback: (data: any) => void) => void
  onSyncState?: (
    callback: (stateToUpdate: string, payload: any) => void,
  ) => void
  onSyncMultipleState?: (callback: (payload: string) => void) => void
  onGotNavigation?: (callback: (data: any) => void) => void
  onGotReload?: (callback: () => void) => void
  setCameraPos?: (data: any) => void
}

const SocketIOContext = createContext<SocketIOContextType>({
  socket: null,
  room: null,
  roomTaken: null,
  usersInRooms: null,
  setRoomTaken: () => {},
  joinRoom: () => {},
  disconnectSocket: () => {},
  emitSocketEvent: () => {},
  emitSyncStateEvent: () => {},
  emitSyncMultipleStateEvent: () => {},
  onSocketEvent: () => {},
  onSyncState: (callback: (stateToUpdate: string, payload: any) => void) => {},
  onSyncMultipleState: (
    callback: (stateToUpdate: string, payload: any) => void,
  ) => {},
  onGotNavigation: (callback: (data: any) => void) => {},
  onGotReload: (callback: () => void) => {},
  setCameraPos: () => {},
})

type Action =
  | { type: "SET_ROOM"; payload: Room | null }
  | { type: "SET_ROOM_TAKEN"; payload: any }
  | { type: "USERS_IN_ROOM"; payload: any }

const initialState = {
  room: null as Room | null,
  roomTaken: null,
  usersInRooms: null,
}

const reducer = (state: typeof initialState, action: Action) => {
  switch (action.type) {
    case "SET_ROOM":
      return { ...state, room: action.payload }
    case "SET_ROOM_TAKEN":
      return { ...state, roomTaken: action.payload }
    case "USERS_IN_ROOM":
      // return { ...state, usersInRooms: action.payload.reduce((cumulator, item) => cumulator[item.room] = item, {})}
      return { ...state, usersInRooms: action.payload }
    default:
      return state
  }
}

export const useSocketIO = (): SocketIOContextType =>
  useContext(SocketIOContext)

type Host = `https://${string}` | `http://${string}`

type SocketIOURL = `${Host}:${number}`

interface SocketIOProviderProps {
  url: SocketIOURL
}

/**
 * Function to create a socket using socket.io
 * @param url - The URL for the socket connection
 * @returns Socket instance
 */
const createSocket = (url: string) => {
  const socket = SocketClient.getInstance(url)
  return socket.getSocket()
}

export const SocketIOProvider: FC<
  SocketIOProviderProps & PropsWithChildren
> = ({ url, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  // Create the socket instance when the component mounts
  const socket = createSocket(url)

  const handleBeforeUnload = (event: any) => {
    // Execute logic before the page reloads
    socket?.emit("pageReload", { room: state.room, socketId: socket?.id })
    localStorage.removeItem("room")
  }

  useEffect(() => {
    window.addEventListener("unload", handleBeforeUnload)

    return () => {
      window.removeEventListener("unload", handleBeforeUnload)
    }
  }, [])

  const joinRoom = (args: { room: Room; payload: any }) => {
    const { room, payload } = args
    socket?.emit("joinRoom", {
      room,
      isTablet: payload.isTablet,
      isReload: payload.isReload,
    })
  }

  const disconnectSocket = (room: Room) => {
    socket?.emit("leaveRoom", room)
    dispatch({ type: "SET_ROOM", payload: null })
  }

  const emitSocketEvent = (eventName: any, data: any) => {
    socket?.emit(eventName, {
      room: state.room,
      ...data,
    })
  }

  const emitSyncStateEvent = (data: any) => {
    socket?.emit("syncState", {
      room: state.room,
      ...data,
      id: socket?.id,
    })
  }

  const emitSyncMultipleStateEvent = (data: object) => {
    const stringified = JSON.stringify(data)
    socket?.emit("syncMultipleState", {
      // room: state.room,
      payload: stringified,
      id: socket?.id,
    })
  }

  const setCameraPos = (data: any) => {
    socket?.emit("cameraPos", {
      room: state.room,
      data,
      viewSide: data.viewSide,
    })
  }

  const onSocketEvent = (eventName: any, callback: (data: any) => void) => {
    socket?.on(eventName, callback)
  }

  const onSyncState = (
    callback: (stateToUpdate: string, payload: any) => void,
  ) => {
    socket?.on("got-syncState", (data: any) => {
      if (data?.id === socket?.id) {
        return
      }

      const { stateToUpdate, payload } = data
      callback(stateToUpdate, payload)
    })
  }

  const onSyncMultipleState = (callback: (payload: string) => void) => {
    socket?.on("syncMultipleState", (data: any) => {
      if (data?.id === socket?.id) {
        return
      }

      const { payload } = data
      const parsed = JSON.parse(payload)
      callback({ ...parsed })
    })
  }

  const onGotNavigation = (callback: (data: any) => void) => {
    socket?.on("got-navigation", (payload: any) => {
      if (payload.id !== socket?.id) {
        callback(payload)
        console.log("Got navigation", payload)
      }
    })
  }

  const onGotReload = (callback: () => void) => {
    socket?.on("got-reload", (payload: any) => {
      if (payload.id !== socket?.id) {
        callback()
      }
    })
  }

  const setRoomTaken = (isTaken: boolean) => {
    dispatch({ type: "SET_ROOM_TAKEN", payload: isTaken })
  }

  // Mount socket instance
  useEffect(() => {
    console.log("SocketIOProvider mounted", url)

    socket.on("connect", () => {
      console.log("Socket connected")
      socket?.emit("getUsersInRooms")
    })

    socket.on("error", (error: any) => {
      console.error("Socket error:", error)
    })

    socket.on("disconnect", () => {
      console.log("Socket disconnected")
      dispatch({ type: "SET_ROOM", payload: null })
      // Socket is automatically closed
      // but we don't want to remove listeners and close it again.
      // We want to keep the listeners and socket instance so we can reconnect automatically
      // when the server is back up.
      // socket?.removeAllListeners()
      // socket.close()
    })

    // Custom States
    socket.on("joinedRoom", (data) => {
      console.log("Joined room", data)
      socket?.emit("getUsersInRooms")
      dispatch({ type: "SET_ROOM", payload: data.room })
      dispatch({ type: "USERS_IN_ROOM", payload: data.usersInRooms })
    })

    socket.on("updatedUsersInRooms", (data: any) => {
      console.log("Updated list of users in rooms", data)
      dispatch({ type: "USERS_IN_ROOM", payload: data.usersInRooms })
    })

    socket.on("roomTaken", (data: any) => {
      dispatch({ type: "SET_ROOM_TAKEN", payload: data })
    })

    socket.on("switchedRoom", (data) => {
      dispatch({ type: "SET_ROOM", payload: data.room })
    })

    socket.on("reload", (data) => {
      console.log("Reload", data)
      dispatch({ type: "USERS_IN_ROOM", payload: data.usersInRooms })
    })

    socket.on("disconnectAll", (room: Room) => {
      console.log("Disconnect all", room)
      socket.emit("leaveRoom", room)
    })

    socket.on("kicked", (data: any) => {
      console.log("Kicked", data)
      socket.emit("leaveRoom", data)
      dispatch({ type: "SET_ROOM", payload: null })
      dispatch({ type: "SET_ROOM_TAKEN", payload: false })
    })

    return () => {
      console.log("SocketIOProvider unmounted")
      socket?.removeAllListeners()
      dispatch({ type: "SET_ROOM", payload: null })
    }
  }, [])

  return (
    <SocketIOContext.Provider
      value={{
        socket,
        room: state.room,
        roomTaken: state.roomTaken,
        usersInRooms: state.usersInRooms,
        joinRoom,
        disconnectSocket,
        emitSocketEvent,
        emitSyncStateEvent,
        emitSyncMultipleStateEvent,
        setRoomTaken,
        onSocketEvent,
        onSyncState,
        onSyncMultipleState,
        onGotNavigation,
        onGotReload,
        setCameraPos,
      }}
    >
      {children}
    </SocketIOContext.Provider>
  )
}
