import { useStore } from "@/state/store"
import { useSyncStatesWithSocket } from "@/utils/useSyncStatesWithSocket"

const Interface = () => {
  return (
    <>
      <Back />
    </>
  )
}

export default Interface

const Back = () => {
  const activeId = useStore((state) => state.activeId)
  const { syncStatesWithSocket } = useSyncStatesWithSocket()
  const handleBack = () => {
    const newActiveId = Math.max(0, activeId - 1)
    syncStatesWithSocket({ activeId: newActiveId })
  }
  if (activeId === 0) return null
  return (
    <div
      style={{
        cursor: "pointer",
        display: "grid",
        placeSelf: "end start",
        border: "1px solid #AD9780",
        margin: "4rem",
        color: "#AD9780",
        padding: "0.75rem",
        fontSize: 12,
        borderRadius: "1.5rem",
        width: "4.5rem",
        height: "3rem",
      }}
      onClick={() => {
        activeId !== 0 && handleBack()
      }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 77 71"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M48.375 70.2502H4.625C2.9062 70.2502 1.5 68.844 1.5 67.1252C1.5 65.4064 2.9062 64.0002 4.625 64.0002H48.375C60.437 64.0002 70.25 54.1877 70.25 42.1252C70.25 36.3127 67.9688 30.7812 63.8438 26.6252C59.6876 22.5314 54.1876 20.2502 48.3748 20.2502H11.4058L19.3433 28.1877C20.5621 29.4065 20.5621 31.3752 19.3433 32.5939C18.1245 33.8126 16.1558 33.8127 14.9371 32.5939L1.6561 19.3439C1.06235 18.7501 0.749847 17.9689 0.749847 17.1251C0.749847 16.2813 1.0936 15.5001 1.6561 14.9063L14.9061 1.65629C16.1249 0.437487 18.0936 0.437487 19.3123 1.65629C20.531 2.87509 20.5311 4.84379 19.3123 6.06249L11.4061 14H48.3751C55.8751 14 62.9371 16.9062 68.2501 22.2188C73.5939 27.5626 76.5001 34.6248 76.5001 42.1248C76.5001 57.6248 63.875 70.2502 48.375 70.2502Z"
          fill="#AD9780"
        />
      </svg>
    </div>
  )
}
