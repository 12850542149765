import { create } from "zustand"

export interface InitialState {
  setLocalState: (state: Partial<InitialState>) => void
  section: "OPENING STATEMENT" | "MASTERPLAN" | "VILLAS" | "GALLERY" | "SPLASH"
  openingState: number
  activeId: any

  //Villas
  sample: number
  textureIndex: number
  tourStyle: "Reception" | "Workspace"
  galleryOpen: boolean
  loading: boolean
  setLoading: (v: boolean) => void
  galleryIndex: number | null
}

export const useStore = create<InitialState>()((set, get) => ({
  setLocalState: (stateToUpdate) => {
    set({ ...stateToUpdate })
  },

  section: "SPLASH",
  openingState: 0,
  activeId: 0,

  //Villas
  sample: 0,
  textureIndex: 0,
  tourStyle: "Reception",
  galleryOpen: false,
  loading: true,
  setLoading: (v) => set({ loading: v }),
  galleryIndex: null,
}))
