import { globalStyles } from "@/utils/globalStyles"
import React from "react"

export default function Background() {
  return (
    <div
      style={{
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        display: "grid",
        placeItems: "center",
        backgroundColor: globalStyles.col.grey,
        zIndex: -1,
        height: "100%",
        width: "100%",
      }}
    >
      <video
        autoPlay
        muted
        playsInline
        loop
        style={{
          height: "100%",
          width: "100%",
          mixBlendMode: "multiply",
          opacity: 0.1,
          objectFit: "cover",
          overflow: "hidden",
          mask: "linear-gradient(to bottom, transparent 0%, transparent 30%, black 100%)",
        }}
      >
        <source src="/videos/bg.mp4" type="video/mp4" />
      </video>
    </div>
  )
}
