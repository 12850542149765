import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react"

interface WebSocketContextType {
  send: (message: string) => void
  socket: WebSocket | null
}

const WebSocketContext = createContext<WebSocketContextType>({
  send: () => {},
  socket: null,
})

export const useWebSocket = (): WebSocketContextType =>
  useContext(WebSocketContext)

type Host = `wss://${string}` | `ws://${string}`

type WebSocketURL = `${Host}:${number}`

interface WebSocketProviderProps {
  url: WebSocketURL
}

const connect = (url: string) => {
  const socket = new WebSocket(url)

  return socket
}

/**
 * WebSocketProvider is a React provider component that provides a WebSocket connection to its children.
 * @params props - The props of the component.
 * @param props.url - The url to connect to the websocket server with (e.g. ws://192.168.1.1:3030)
 * @returns A React component.
 */
export const WebSocketProvider: FC<
  WebSocketProviderProps & PropsWithChildren
> = ({ url, children }) => {
  const [newSocket, setNewSocket] = useState<WebSocket | null>(null)

  const tryConnect = () => {
    const socket = connect(url)

    socket.onopen = () => {
      console.log("WebSocket connection established")
      setNewSocket(socket)
    }

    socket.onmessage = (event) => {
      console.log("WebSocket message received:", event.data)
    }

    socket.onerror = (error) => {
      console.error("WebSocket error:", error)
      socket.close()
    }

    socket.onclose = () => {
      console.log("WebSocket connection closed")
      setTimeout(tryConnect, 1000)
    }
  }

  useEffect(() => {
    tryConnect()

    return () => {
      newSocket && newSocket.close()
    }
  }, [])

  const send = (message: string) => {
    if (newSocket) {
      newSocket.send(message)
    }
  }

  return (
    <WebSocketContext.Provider value={{ send, socket: newSocket }}>
      {children}
    </WebSocketContext.Provider>
  )
}
