import * as THREE from "three"
import { Loader } from "./Loader"
import { createWorker } from "./splat_worker"
import { update, connect, pushDataBuffer } from "./splat_imp"

export class SplatLoader extends Loader {
  constructor(...args) {
    super(...args)
    // WebGLRenderer, needs to be filled out!
    this.gl = null
    // Default chunk size for lazy loading
    this.chunkSize = 1 // DEFAULT_CHUNK_SIZE
  }

  load(url, onLoad, onProgress, onError) {
    // console.log("CREATE", createWorker.toString())
    const shared = {
      gl: this.gl,
      url: this.manager.resolveURL(url),
      worker: new Worker(
        URL.createObjectURL(
          new Blob(["(", createWorker.toString(), ")(self)"], {
            type: "application/javascript",
          }),
        ),
      ),
      manager: this.manager,
      update: (target, camera, hashed) =>
        update(camera, shared, target, hashed),
      connect: (target) => connect(shared, target),
      loading: false,
      loaded: false,
      loadedVertexCount: 0,
      chunkSize: this.chunkSize,
      totalDownloadBytes: 0,
      numVertices: 0,
      rowLength: 3 * 4 + 3 * 4 + 4 + 4,
      maxVertexes: 0,
      bufferTextureWidth: 0,
      bufferTextureHeight: 0,
      stream: null,
      centerAndScaleData: null,
      covAndColorData: null,
      covAndColorTexture: null,
      centerAndScaleTexture: null,
      customIDValue: null,
      onProgress,
    }
    loadSplatData(shared)
      .then(onLoad)
      .catch((e) => {
        onError == null || onError(e)
        shared.manager.itemError(shared.url)
      })
  }
}

async function loadSplatData(shared) {
  // console.log(shared.url)
  shared.manager.itemStart(shared.url)
  const data = await fetch(shared.url)
  // console.log("data::", data)
  if (data.body === null) throw "Failed to fetch file"
  let _totalDownloadBytes = data.headers.get("Content-Length")

  // console.log("_totalDownloadBytes::", _totalDownloadBytes)

  const totalDownloadBytes = _totalDownloadBytes
    ? parseInt(_totalDownloadBytes)
    : undefined

  if (totalDownloadBytes == undefined) throw "Failed to get content length"
  shared.stream = data.body.getReader()
  shared.totalDownloadBytes = totalDownloadBytes
  shared.numVertices = Math.floor(shared.totalDownloadBytes / shared.rowLength)
  const context = shared.gl.getContext()
  let maxTextureSize = context.getParameter(context.MAX_TEXTURE_SIZE)
  shared.maxVertexes = maxTextureSize * maxTextureSize

  if (shared.numVertices > shared.maxVertexes)
    shared.numVertices = shared.maxVertexes

  shared.bufferTextureWidth = maxTextureSize

  shared.bufferTextureHeight =
    Math.floor((shared.numVertices - 1) / maxTextureSize) + 1
  shared.centerAndScaleData = new Float32Array(
    shared.bufferTextureWidth * shared.bufferTextureHeight * 4,
  )
  // console.log("PRE SET::", shared.centerAndScaleData)

  shared.covAndColorData = new Uint32Array(
    shared.bufferTextureWidth * shared.bufferTextureHeight * 4,
  )

  shared.customIDValue = new THREE.DataTexture(
    shared.centerAndScaleData,
    shared.bufferTextureWidth,
    shared.bufferTextureHeight,
    THREE.RGBAFormat,
    THREE.FloatType,
  )

  shared.customIDValue.needsUpdate = true

  shared.centerAndScaleTexture = new THREE.DataTexture(
    shared.centerAndScaleData,
    shared.bufferTextureWidth,
    shared.bufferTextureHeight,
    THREE.RGBAFormat,
    THREE.FloatType,
  )
  shared.centerAndScaleTexture.needsUpdate = true
  shared.covAndColorTexture = new THREE.DataTexture(
    shared.covAndColorData,
    shared.bufferTextureWidth,
    shared.bufferTextureHeight,
    THREE.RGBAIntegerFormat,
    THREE.UnsignedIntType,
  )

  shared.covAndColorTexture.internalFormat = "RGBA32UI"
  shared.covAndColorTexture.needsUpdate = true
  // console.log("shared::", shared)
  return shared
}
export async function lazyLoad(shared) {
  shared.loading = true
  let bytesDownloaded = 0
  let bytesProcessed = 0
  const chunks = []
  let lastReportedProgress = 0
  const lengthComputable = shared.totalDownloadBytes !== 0
  while (true) {
    try {
      const { value, done } = await shared.stream.read()
      if (done) break
      bytesDownloaded += value.length
      // console.log(bytesDownloaded)
      if (shared.totalDownloadBytes != undefined) {
        const percent = (bytesDownloaded / shared.totalDownloadBytes) * 100
        if (shared.onProgress && percent - lastReportedProgress > 1) {
          const event = new ProgressEvent("progress", {
            lengthComputable,
            loaded: bytesDownloaded,
            total: shared.totalDownloadBytes,
          })
          shared.onProgress(event)
          lastReportedProgress = percent
        }
      }
      chunks.push(value)
      const bytesRemains = bytesDownloaded - bytesProcessed

      if (
        shared.totalDownloadBytes != undefined &&
        bytesRemains > shared.rowLength * shared.chunkSize
      ) {
        let vertexCount = Math.floor(bytesRemains / shared.rowLength)
        const concatenatedChunksbuffer = new Uint8Array(bytesRemains)
        let offset = 0
        for (const chunk of chunks) {
          concatenatedChunksbuffer.set(chunk, offset)
          offset += chunk.length
        }
        chunks.length = 0
        if (bytesRemains > vertexCount * shared.rowLength) {
          const extra_data = new Uint8Array(
            bytesRemains - vertexCount * shared.rowLength,
          )

          extra_data.set(
            concatenatedChunksbuffer.subarray(
              bytesRemains - extra_data.length,
              bytesRemains,
            ),
            0,
          )
          chunks.push(extra_data)
        }

        const buffer = new Uint8Array(vertexCount * shared.rowLength)

        buffer.set(concatenatedChunksbuffer.subarray(0, buffer.byteLength), 0)

        const matrices = pushDataBuffer(shared, buffer.buffer, vertexCount)
        shared.worker.postMessage(
          {
            method: "push",
            src: shared.url,
            length: shared.numVertices * 16,
            matrices: matrices.buffer,
          },
          [matrices.buffer],
        )
        bytesProcessed += vertexCount * shared.rowLength
        if (shared.onProgress) {
          const event = new ProgressEvent("progress", {
            lengthComputable,
            loaded: shared.totalDownloadBytes,
            total: shared.totalDownloadBytes,
          })
          shared.onProgress(event)
        }
      }
    } catch (error) {
      console.error(error)
      break
    }
  }
  if (bytesDownloaded - bytesProcessed > 0) {
    // Concatenate the chunks into a single Uint8Array
    let concatenatedChunks = new Uint8Array(
      chunks.reduce((acc, chunk) => acc + chunk.length, 0),
    )
    let offset = 0
    for (const chunk of chunks) {
      concatenatedChunks.set(chunk, offset)
      offset += chunk.length
    }
    let numVertices = Math.floor(
      concatenatedChunks.byteLength / shared.rowLength,
    )
    const matrices = pushDataBuffer(
      shared,
      concatenatedChunks.buffer,
      numVertices,
    )
    shared.worker.postMessage(
      {
        method: "push",
        src: shared.url,
        length: numVertices * 16,
        matrices: matrices.buffer,
      },
      [matrices.buffer],
    )
  }
  shared.loaded = true
  shared.manager.itemEnd(shared.url)
}
