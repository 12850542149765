import React from "react"

export default function SVGLogo({ color, ...props }) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      {...props}
    >
      <rect fill="#FFFFFF" width="100" height="100" />
      <g>
        <path
          fill="#004243"
          d="M25.3,39.7h2.5c2.5,0,4.3,1.1,4.3,3.7c0,2.6-1.8,3.8-4.3,3.8h-2.5V39.7z M27.8,46.9c1.9,0,2.8-1.3,2.8-3.5
		S29.7,40,27.8,40h-1.1v6.9H27.8z"
        />
        <path fill="#004243" d="M34.8,39.7h1.3v7.6h-1.3V39.7z" />
        <path
          fill="#004243"
          d="M40.6,43.7v3.5h-1.3v-7.6h2.4c2.2,0,3.3,0.7,3.3,2c0,1.1-1,1.7-2.2,1.8v0c1,0.1,1.7,0.5,2,1.7l0.5,2.1h-1.4
		l-0.5-2.2c-0.3-1.1-0.8-1.4-1.7-1.4H40.6L40.6,43.7z M40.6,43.3h1c1.3,0,1.9-0.7,1.9-1.7c0-1-0.6-1.7-1.9-1.7h-1V43.3z"
        />
        <path fill="#004243" d="M47.9,39.7h1.3v7.6h-1.3V39.7z" />
        <path
          fill="#004243"
          d="M55.5,44.2v3.1h-1.3v-2.9l-2.6-4.6h1.5l2.2,4l2.1-4h0.5L55.5,44.2L55.5,44.2z"
        />
        <path
          fill="#004243"
          d="M63.8,44.9h-3.3l-0.9,2.4h-0.4l2.8-7.6h1.1l3,7.6h-1.4L63.8,44.9z M63.7,44.5l-1.5-4l-1.5,4H63.7z"
        />
        <path
          fill="#004243"
          d="M68.5,39.7h1.3v3.5h3.8v-3.5H75v7.6h-1.3v-3.7h-3.8v3.7h-1.3V39.7z"
        />
        <path
          fill="#004243"
          d="M25,57.2h1.2c0.1,1.3,0.7,2,1.9,2c1,0,1.9-0.5,1.9-1.4c0-0.8-0.6-1.2-1.7-1.5L27.4,56
		c-1.4-0.4-2.1-0.9-2.1-2.1c0-1.4,1.3-2.3,2.8-2.3c1.5,0,2.6,0.7,2.8,2.1h-1.2c-0.1-1.1-0.6-1.7-1.6-1.7c-1,0-1.7,0.6-1.7,1.4
		c0,0.7,0.5,1.1,1.5,1.4l0.9,0.2c1.4,0.4,2.2,0.9,2.2,2.2c0,1.6-1.4,2.3-3,2.3C26.4,59.5,25.2,58.7,25,57.2L25,57.2z"
        />
        <path
          fill="#004243"
          d="M39.6,60.3l-0.9-1c-0.4,0.1-0.8,0.2-1.2,0.2c-2.1,0-3.8-1.5-3.8-3.9c0-2.4,1.8-3.9,3.8-3.9
		c2,0,3.8,1.4,3.8,3.9c0,1.7-0.9,3-2.2,3.6l0.8,0.9L39.6,60.3L39.6,60.3z M37.4,59.2c0.3,0,0.7-0.1,0.9-0.2l-1.2-1.3l0.3-0.3
		l1.2,1.3c0.7-0.6,1.1-1.6,1.1-3.2c0-2.3-0.8-3.6-2.4-3.6c-1.5,0-2.4,1.2-2.4,3.6C35,57.9,35.9,59.2,37.4,59.2L37.4,59.2z"
        />
        <path
          fill="#004243"
          d="M43.7,56.3v-4.5H45v4.6c0,1.8,0.8,2.6,2.1,2.6c1.3,0,2.1-0.9,2.1-2.6v-4.7h0.4v4.7c0,1.9-1,3-2.8,3
		C45,59.5,43.7,58.5,43.7,56.3L43.7,56.3z"
        />
        <path
          fill="#004243"
          d="M56.3,57h-3.3l-0.9,2.4h-0.4l2.8-7.6h1.1l3,7.6h-1.4L56.3,57z M56.2,56.7l-1.5-4l-1.5,4H56.2z"
        />
        <path
          fill="#004243"
          d="M62.3,55.9v3.5H61v-7.6h2.4c2.2,0,3.3,0.7,3.3,2c0,1.1-1,1.7-2.2,1.8v0c1,0.1,1.7,0.5,2,1.7l0.5,2.1h-1.4
		L65,57.2c-0.3-1.1-0.8-1.4-1.7-1.4H62.3L62.3,55.9z M62.3,55.5h1c1.3,0,1.9-0.7,1.9-1.7c0-1-0.6-1.7-1.9-1.7h-1V55.5z"
        />
        <path
          fill="#004243"
          d="M75,59.4h-5.3v-7.6h5.2v0.4H71v3.1h3.6v0.4H71V59h4V59.4z"
        />
      </g>
    </svg>
  )
}
