import { useSocketIO } from "@/providers/sockets/SocketIOProvider"
import { InitialState, useStore } from "@/state/store"
import { useEffect } from "react"
import { useIsMaster } from "./isMaster"

export const useSyncStatesWithSocket = () => {
  const setLocalState = useStore((state) => state.setLocalState)
  const { emitSyncMultipleStateEvent } = useSocketIO()

  const syncStatesWithSocket = (payload: Partial<InitialState>) => {
    emitSyncMultipleStateEvent(payload)
    setLocalState(payload)
  }

  return {
    syncStatesWithSocket,
  }
}

export const SetLocalState = () => {
  const { onSyncMultipleState } = useSocketIO()
  const setLocalState = useStore((state) => state.setLocalState)
  const { isMaster } = useIsMaster()
  useEffect(() => {
    if (isMaster) return
    onSyncMultipleState((payload: any) => {
      console.log(payload)
      setLocalState(payload)
    })
  }, [])
  return null
}
