import { createRoot } from "react-dom/client"

import "./style.css"

import App from "./App"
import { SocketIOProvider } from "./providers/sockets"

const container: HTMLElement = document.getElementById("root")
const root = createRoot(container!)

root.render(
  <SocketIOProvider url={import.meta.env.VITE_SOCKET_IP}>
    <App />
  </SocketIOProvider>,
)
