import { useEffect } from "react"
import Content from "./Content"
import Background from "./components/Background/Background"
import Nav from "./components/UI/Nav"
import { useStore } from "./state/store"
import { useSocketIO } from "./providers/sockets/SocketIOProvider"
import { useIsMaster } from "./utils/isMaster"
import Splash from "./components/Background/Splash"
import { SetLocalState } from "./utils/useSyncStatesWithSocket"
import Masterplan from "./components/Masterplan/Masterplan"
import Villas from "./components/Villas/Villas"

function DefaultLayout() {
  const section = useStore((state) => state.section)
  const { isMaster } = useIsMaster()

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: "100%",
        width: "100%",
        display: "grid",
      }}
    >
      {section === "SPLASH" && <Splash />}
      {isMaster && section !== "SPLASH" && <Nav />}
      {isMaster && section === "OPENING STATEMENT" && <Background />}
      {section === "MASTERPLAN" && <Masterplan />}
      {/* {section === "VILLAS" && <Villas />} */}
      <SetLocalState />
      <Content />
    </div>
  )
}

export default DefaultLayout
