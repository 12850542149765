import { useLocation } from "react-router-dom"

export const useIsMaster = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  //   console.log(searchParams.has("master"))
  const isMaster = searchParams.has("master") ? true : false
  return {
    isMaster,
  }
}
