import ErrorMessage from "@/pages/ErrorMessage"
import Dashboard from "@/pages/Dashboard"

const routes = [
  {
    name: "Dashboard",
    path: "/",
    exact: true,
    element: Dashboard,
  },
  { path: "/*", name: "Not Found", element: ErrorMessage },
]

export default routes
