import { BrowserRouter, Route, Routes } from "react-router-dom"
import { Button } from "vmi-component-library"
import DefaultLayout from "./DefaultLayout"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<DefaultLayout />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
