import { OrbitControls } from "@react-three/drei"
import { useFrame, useThree } from "@react-three/fiber"
import { useEffect, useRef, useState } from "react"
import { PerspectiveCamera, Quaternion, Vector3 } from "three"
import gsap from "gsap"
import { useIsMaster } from "@/utils/isMaster"
import { useSocketIO } from "@/providers/sockets/SocketIOProvider"
import { throttle } from "lodash"

const cameraData = {
  0: {
    position: new Vector3(-20, 11, -4),
    target: new Vector3(0, 0, 5),
  },
  1: {
    position: new Vector3(15, 8, -4),
    target: new Vector3(0, 0, 0),
  },
  2: {
    position: new Vector3(-4, 4, -4),
    target: new Vector3(0, 0, 0),
  },
  3: {
    position: new Vector3(-2, 2, -2),
    target: new Vector3(0, 0, 0),
  },
}

//
function AnimatedCamera(props) {
  const { isMaster } = useIsMaster()
  const { emitSocketEvent } = useSocketIO()
  const { activeId } = props
  const orbitRef = useRef(null)

  const { camera } = useThree()

  const currentTarget = useRef(new Vector3(0, 0, 0))

  //Set position
  useEffect(() => {
    if (orbitRef.current) {
      const controls = orbitRef.current

      gsap.to(controls.object.position, {
        x: cameraData[activeId]?.position.x,
        y: cameraData[activeId]?.position.y,
        z: cameraData[activeId]?.position.z,
        duration: 1.5,
        ease: "power4.out",
        onStart: () => (controls.enableRotate = false),
        onComplete: () => (controls.enableRotate = true),
      })

      gsap.to(currentTarget.current, {
        x: cameraData[activeId]?.target.x,
        y: cameraData[activeId]?.target.y,
        z: cameraData[activeId]?.target.z,
        duration: 1.5,
        ease: "power4.out",
        onUpdate: () => {
          controls.target.copy(currentTarget.current)
          controls.update()
        },
      })
    }
  }, [activeId])

  const throttledEmit = useRef(
    throttle((data) => emitSocketEvent("cameraPos", data), 50),
  ).current

  return (
    <OrbitControls
      ref={orbitRef}
      minPolarAngle={0}
      maxPolarAngle={Math.PI * 0.5 - 0.33}
      onChange={() => {
        if (!isMaster || !orbitRef.current) return

        const quaternion = new Quaternion()
        camera.getWorldQuaternion(quaternion)

        const cameraData: any = {
          x: camera.position.x,
          y: camera.position.y,
          z: camera.position.z,
          qx: quaternion.x,
          qy: quaternion.y,
          qz: quaternion.z,
          qw: quaternion.w,
        }

        if ("fov" in camera) {
          cameraData.fov = (camera as PerspectiveCamera).fov
        }

        throttledEmit({ data: cameraData })
      }}
    />
  )
}
export default AnimatedCamera
