import { globalStyles } from "@/utils/globalStyles"
import { useIsMaster } from "@/utils/isMaster"
import { Html, Text } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"
import gsap from "gsap"
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { Group } from "three"

const color = "#fff"

const AnimatedMaterial = (props) => {
  const { color, active } = props

  return <meshBasicMaterial color={color} opacity={1} transparent />
}

export default function Label({
  text,
  position,
  height,
  id,

  activeId,
  setActiveId,
}) {
  const { isMaster } = useIsMaster()
  const active = activeId === id
  const ref = useRef<Group>()

  const scale = 6

  useFrame(({ camera }) => {
    const distance = camera.position.distanceTo(position)

    ref.current.scale.set(distance / scale, distance / scale, distance / scale)
  })

  useEffect(() => {
    gsap.to(ref.current.scale, {})
  }, [active])

  return (
    <group visible={active} ref={ref} position={position}>
      <mesh>
        <cylinderGeometry args={[0.006, 0.006, height, 16]} />
        <AnimatedMaterial color={color} active={active} />
      </mesh>
      <mesh position-y={height * -0.5}>
        <sphereGeometry args={[0.02, 12, 12]} />
        <AnimatedMaterial color={color} active={active} />
      </mesh>
      <group position-y={height * 0.5 + height * 0.2}>
        <Html center>
          <p
            onClick={setActiveId}
            style={{
              cursor: "pointer",
              pointerEvents: active && isMaster ? "auto" : "none",
              visibility: active ? "visible" : "hidden",
              border: "1px solid #AD9780",
              borderRadius: "1.5rem",
              backgroundColor: globalStyles.col.purple,
              userSelect: "none",
              color: color,
              opacity: 1,
              transition: "opacity 0.4s",
              fontSize: 12,
              letterSpacing: "0.1rem",
              //  width: "13rem",
              width: "max-content",
              height: "max-content",
              padding: "1rem 2rem",
              //  height: "3rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              fontFamily: "BeausiteFitTrial-Regular",
              textTransform: "uppercase",
            }}
          >
            {text}
          </p>
        </Html>
      </group>
    </group>
  )
}
