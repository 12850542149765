import { Canvas, useFrame, useThree } from "@react-three/fiber"
import { Euler, Quaternion, Vector3 } from "three"
import { OrbitControls, PerspectiveCamera } from "@react-three/drei"
import React, { useEffect, useRef, useState } from "react"

import { Splat } from "@/lib/Splat"
import AnimatedCamera from "./AnimatedCamera"
import Labels from "./Labels"
import { useIsMaster } from "@/utils/isMaster"
import { useSocketIO } from "@/providers/sockets/SocketIOProvider"
import { useStore } from "@/state/store"

export const TCanvas = () => {
  const activeId = useStore((state) => state.activeId)
  const { isMaster } = useIsMaster()

  return (
    <div id="canvas_wr">
      <Canvas
        // frameloop="demand"
        camera={{
          position: new Vector3(-4, 6, 4),
          fov: 35,
          near: 0.5,
          far: 200,
        }}
        dpr={1.5}
      >
        <React.Suspense fallback={null}>
          <Labels />
          {/* <gridHelper position-y={-1} scale={0.5} args={[100, 100, "#2c2c2c", "#2c2c2c"]} /> */}
          {/* <axesHelper scale={2.5} /> */}

          <SplatScene activeId={activeId} />
          {isMaster && <AnimatedCamera activeId={activeId} />}
        </React.Suspense>
        {/* {isMaster && <OrbitControls />} */}
        {!isMaster && <Perspective />}
      </Canvas>
    </div>
  )
}

const SplatScene = ({ activeId }) => {
  return (
    <group position={[-3.4, 0, 3.6]} scale={1} rotation-x={Math.PI * -0.5}>
      <group>
        <Splat
          visible={activeId < 2}
          s_id={1}
          src="splats/CROP_masterplan_masterplan.splat"
          active={activeId === 0}
        />
        <Splat
          visible={activeId < 2}
          s_id={0}
          src="splats/CROP_masterplan_dsq.splat"
          active={true}
        />
      </group>
      <group scale={0.01}>
        <Splat
          visible={activeId === 2}
          s_id={2}
          src="splats/dsq.splat"
          active={activeId === 2}
        />
      </group>
      <group scale={0.01}>
        <Splat
          visible={activeId === 3}
          s_id={3}
          src="splats/detail.splat"
          active={activeId === 3}
        />
      </group>
    </group>
  )
}

export default TCanvas

function Perspective() {
  const cameraRef = useRef<THREE.PerspectiveCamera>(null)
  const { socket } = useSocketIO()
  const { isMaster } = useIsMaster()
  const { camera, set } = useThree()

  const [targetPosition] = useState(new Vector3(0, 0, 50))
  const [targetQuaternion] = useState(new Quaternion())
  const [targetFov, setTargetFov] = useState<number>(25)

  useEffect(() => {
    if (isMaster) return

    const handleCameraPos = (data: any) => {
      targetPosition.set(data.data.x, data.data.y, data.data.z)
      targetQuaternion.set(
        data.data.qx,
        data.data.qy,
        data.data.qz,
        data.data.qw,
      )
      setTargetFov(data.data.fov)
    }

    socket?.on("cameraPos", handleCameraPos)

    return () => {
      socket?.off("cameraPos", handleCameraPos)
    }
  }, [socket, isMaster, targetPosition, targetQuaternion])

  useFrame(() => {
    if (cameraRef.current && !isMaster) {
      cameraRef.current.position.lerp(targetPosition, 0.1)
      cameraRef.current.quaternion.slerp(targetQuaternion, 0.1)
      cameraRef.current.fov += (targetFov - cameraRef.current.fov) * 0.1
      cameraRef.current.updateProjectionMatrix()

      // Sync the main camera with our controlled camera
      camera.position.copy(cameraRef.current.position)
      camera.quaternion.copy(cameraRef.current.quaternion)
      if ("fov" in camera) {
        ;(camera as THREE.PerspectiveCamera).fov = cameraRef.current.fov
      }
      camera.updateProjectionMatrix()

      // Update the default camera
      set({ camera: cameraRef.current })
    }
  })

  return (
    <PerspectiveCamera
      ref={cameraRef}
      makeDefault
      position={[0, 0, 50]}
      fov={35}
    />
  )
}
